*,
::before,
::after {
  box-sizing: border-box;
}

a {
  &:active,
  &:focus {
    outline: none;
  }
}

input,
textarea,
input:active,
textarea:active,
button:focus,
:focus {
  outline: none;
}

textarea {
  resize: none;
  resize: vertical;
  resize: horizontal;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1440px;
  }
}

.h1 {
  font-size: 35px;
  font-family: 'Roboto', sans-serif;
}

.h2 {
  font-size: 22px;
  line-height: 150%;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  letter-spacing: -0.03em;
  color: $blue;
}

a.h2 {
  text-decoration: none;

  &:hover {
    color: $blue;
    text-decoration: underline;
  }
}

.h3 {
  font-size: 18px;
  color: #d1dae2;
  font-weight: 500;
}

input {
  outline: none;
}

.header_box {
  margin-top: 29px;
  margin-bottom: 31px;
}

.logo {
  width: 139px;
  height: 26px;
  margin-left: 120px;
  display: inline-block;
  background: url(../images/logo.svg) no-repeat left center/contain;
}

.header_box .point,
.search_box .point {
  border: none;
  display: inline-block;
  padding-left: 18px;
  margin-left: 52px;
  position: relative;
  background-color: #fff;
}

.header_box .point:before,
.search_box .point:before {
  width: 13px;
  height: 16px;
  top: 9px;
  left: 0;
  content: '';
  position: absolute;
  background: url(../images/icon/marker_blue.svg);
}

.header_box .point:after,
.search_box .point:after {
  width: 12px;
  height: 6px;
  top: 15px;
  right: -18px;
  content: '';
  position: absolute;
  background: url(../images/icon/down_blue.svg);
}

.col-auto.mr-auto.mobile {
  display: flex;
}

.search_box .point {
  display: none;
  margin-left: 0;
  margin-bottom: 18px;
  color: #fff;
  background: none;

  &:before {
    top: 2px;
    background: url(../images/icon/point_white.svg);
  }

  &:after {
    top: 8px;
    margin-left: 3px;
    background: url(../images/icon/trans_white.svg);
  }

  @include respond-max('tablet') {
    display: block;
  }
}

.enter {
  margin-right: 120px;

  button {
    margin-left: 17px;
  }
}

.input_reg,
.input_enter,
.btn_search,
.btn_resume,
.btn_vacancy,
.btn_lite {
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  position: relative;
  border-radius: 100px;
  padding: 5px 15px 5px 32px;
  transition: all ease 0.3s;
}

.btn_lite {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: max-content;
  transition: all ease 0.3s;
}

.input_reg:before {
  position: absolute;
  width: 16px;
  height: 16px;
  content: ' ';
  left: 9px;
  top: 8px;
  background-repeat: no-repeat;
}

.input_enter {
  &:before {
    position: absolute;
    width: 16px;
    height: 16px;
    content: ' ';
    left: 9px;
    top: 8px;
    background-repeat: no-repeat;
  }

  color: $blue;
  background: #eff5f9;
}

.input_reg {
  color: #fff;
  background: $blue;
}

.input_enter:before {
  background: url(../images/icon/Shape.svg);
  background-repeat: no-repeat;
}

.input_reg:before {
  background: url(../images/icon/Reg.svg);
  background-repeat: no-repeat;
}

.navbar {
  padding: 0;

  @include respond-max('tablet') {
    margin: 15px -15px 0;
  }
}

.ra-body .navbar {
  @include respond-max('tablet') {
    margin: 15px -15px 0 !important;
  }
}

.navbar-nav {
  padding: 15px;
  width: 100%;
  border-top: 1px solid #e2eaf0;

  li {
    position: relative;
    padding-left: 40px;
  }

  .about_project {
    border-top: 1px solid #e2eaf0;
    margin-top: 10px;
    padding-top: 10px;
  }
}

.navbar-light .navbar-nav .nav-link {
  // padding-left: 25px;
}

.navbar-collapse {
  .enter_icon:before {
    background: url(../images/icon/enter.svg);
  }

  .vacancy:before {
    background: url(../images/icon/vacancy.svg);
  }

  .job_search:before {
    background: url(../images/icon/job_search.svg);
  }

  .post_resume:before {
    background: url(../images/icon/post_resume.svg);
  }

  .search_resume:before {
    background: url(../images/icon/search_resume.svg);
  }

  .enter_icon:before,
  .vacancy:before,
  .job_search:before,
  .post_resume:before,
  .search_resume:before {
    width: 15px;
    height: 25px;
    content: ' ';
    position: absolute;
    background-repeat: no-repeat;
    margin-right: 10px;
    background-position: center;
    left: 12px;
    background-position-y: 5px;
  }
}

.navbar-light {
  .navbar-nav .active > .nav-link {
    color: $blue;
  }

  .navbar-toggler {
    position: absolute;
    top: -46px;
    right: 25px;
    border: none;
    float: right;
    padding: 3px;
    transition: all ease 1s;

    &:not(.collapsed) {
      background: #eff5f9;

      .navbar-toggler-icon {
        background: url('../images/icon/x.svg') no-repeat center;
      }
    }
  }

  .navbar-toggler-icon {
    width: 29px;
    height: 29px;
    padding: 5px;
  }
}

.home-bubbles {
  position: absolute;
  left: 50%;
  top: 0;
  // top: 394px;

  @include respond-max('desktop-small') {
    display: none;
  }

  .home-bubble {
    border-radius: 100%;
    position: absolute;
  }

  .home-bubble:nth-child(1) {
    background-color: #65d3ff;
    width: 63px;
    height: 63px;
    right: 754px;
    top: 388px;
  }

  .home-bubble:nth-child(2) {
    background-color: #4ee2f4;
    width: 121px;
    height: 121px;
    z-index: 2;
    right: 609px;
    top: 480px;
  }

  .home-bubble:nth-child(3) {
    background-color: #3fc8d9;
    width: 210px;
    height: 210px;
    right: 447px;
    top: 371px;
  }

  .home-bubble:nth-child(4) {
    background-color: #65d3ff;
    width: 300px;
    height: 300px;
    left: 331px;
    top: 323px;
  }

  .home-bubble:nth-child(5) {
    background-color: #4ee2f4;
    width: 121px;
    height: 121px;
    left: 704px;
    top: 330px;
  }
}

.search_box {
  position: relative;
  display: flex;
  padding-top: 110px;
  width: 100%;
  min-height: 580px;
  background: url(../images/home_top.jpg) no-repeat center;

  .h1 {
    color: #fff;

    @include respond-max('tablet') {
      font-weight: 400;
      font-size: 22px;
      margin-bottom: 24px;
      line-height: 150%;
    }
  }

  .btn_resume,
  .btn_vacancy {
    color: white;
  }
}

.search_group {
  display: flex;
  flex-wrap: nowrap;
  height: 50px;
  margin-bottom: 30px;
  border-radius: 6px;
  background-color: #fff;
}

.search_input {
  font-size: 16px;
  padding-left: 42px;
  width: 681px;
  padding-right: 35px;
  height: 50px;
  border-radius: 6px;
  color: #000;
  border: none;
  position: relative;
  z-index: 1;
}

.search_input_before,
.dropdown,
.search_filter,
.btn_search {
  position: relative;
  display: table-cell;
}

.search_input_before {
  &:before,
  &:after {
    content: ' ';
    position: absolute;
    background-repeat: no-repeat;
    z-index: 10;
  }

  &:before {
    width: 16px;
    height: 16px;
    top: 17px;
    left: 15px;
    background: url(../images/icon/loop.svg);
  }
}

.close_icon {
  width: 11px;
  height: 11px;
  top: 20px;
  right: 15px;
  background: url(../images/icon/close.svg);
  cursor: pointer;
  position: absolute;
  z-index: 1;
}

.search_city .btn-secondary,
.search_where .btn-secondary {
  padding-left: 35px;
  // margin-top: -5px;
  margin-left: auto;
  color: #778a9b;
  background-color: #fff;
  border: none;
  border-left: 1px solid #ebeef3;
  border-radius: 0;
  height: 50px;
  text-align: left;
  box-shadow: 0 0 0 0 red !important;
}

.search_city .btn-secondary:not(:disabled):not(.disabled):active,
.search_where .btn-secondary:not(:disabled):not(.disabled):active {
  color: #778a9b;
  background-color: #fff;
  border-color: #fff;
}

.search_where .dropdown-menu {
  box-shadow: inset 0px 1px 10px rgba(30, 30, 46, 0.1);

  @include respond-min('tablet') {
    max-width: 139px;
    min-width: 139px;
  }
}

.search_city .btn-secondary {
  width: 228px;
}

.search_where .btn-secondary {
  width: 139px;
  padding-left: 25px;
}

.search_city .btn-secondary:before {
  width: 15px;
  height: 16px;
  top: 17px;
  left: 13px;
  content: ' ';
  position: absolute;
  background: url(../images/icon/point.svg);
  background-repeat: no-repeat;
}

.dropdown-toggle::after {
  width: 12px;
  height: 12px;
  top: 19px;
  right: 13px;
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute;
  background: url(../images/icon/trans.png) no-repeat center;
  transform: rotate(-180deg);
  transition: transform ease 0.3s;
}

.dropdown.show .dropdown-toggle::after {
  // top: 23px;
  transform: rotate(0deg);
}

.show > .btn-secondary.dropdown-toggle {
  color: #778a9b;
  background-color: #fff;
  border-left: 1px solid #ebeef3;
  border-radius: 6px 6px 0 0;
}

.dropdown-menu {
  width: 100%;
  margin: 0;
  border-radius: 0 0 6px 6px;
  border: none;
}

.search_filter {
  height: 50px;
  width: 80px;
  border-left: 1px solid #ebeef3;
  background: url('/images/icon/filter 2.png');
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  content: ' ';
  background-color: #fff;
  position: relative;

  .title {
    color: #fff;
    top: -51px;
    padding: 6px 12px;
    width: 184px;
    left: -54px;
    text-align: center;
    position: absolute;
    border-radius: 6px;
    background-color: rgba(36, 50, 73, 0.35);
    opacity: 0;

    &:after {
      bottom: -6px;
      left: 86px;
      position: absolute;
      content: '';
      border-top: 0.4em solid;
      border-right: 0.4em solid transparent;
      border-bottom: 0;
      border-left: 0.4em solid transparent;
      color: rgba(36, 50, 73, 0.35);
    }
  }

  &:hover .title {
    opacity: 0.9;
    transition: 1s;

    &:after {
      opacity: 0.9;
      transition: 1s;
    }
  }
}

.btn_search {
  padding: 0;
  width: 150px;
  height: 50px;
  color: #000;
  text-align: center;
  border-radius: 0 6px 6px 0;
  background-color: $yellow;
  border-color: $yellow;
}

.btn_resume,
.btn_vacancy,
.btn_lite {
  padding: 6px 20px 7px;
  height: 36px;
  color: #fff;
  background: none;
  border: 1px solid #fff;
  border-radius: 50px;
  text-align: center;

  &:hover {
    opacity: 0.9;
  }
}

.btn_vacancy {
  margin-left: 20px;
}

.partition_box {
  margin-top: -207px;
  font-size: 16px;
  position: relative;
}

.partition {
  padding: 32px 55px 25px;
  background-color: #fff;
  box-shadow: 0 2px 23px -9px rgba(0, 0, 0, 0.17);
  border-radius: 6px;
}

.partition_box {
  .item {
    display: block;
    height: 205px;
    text-align: center;
    cursor: pointer;
    position: relative;
  }

  .circle {
    flex: 0 0 auto;
    width: 114px;
    min-width: 114px;
    height: 114px;
    background-color: #f1faff;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }

  .item {
    &:hover {
      color: $blue;

      .circle {
        background-color: #e2f5ff;
      }
    }

    .circle:after {
      width: 23px;
      height: 23px;
      top: 6px;
      right: 7px;
      position: absolute;
      background: $blue;
      border-radius: 100px;
      content: ' ';
      background-image: url(../images/icon/triangle.png);
      background-repeat: no-repeat;
      background-position: 9px 6px;
      opacity: 0;
    }

    &:hover .circle:after {
      opacity: 1;
      transition: 1s;
    }
  }

  .icon {
    height: 106px;
    width: 100%;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
  }

  .text {
    padding-top: 17px;

    span {
      padding-top: 5px;
      font-size: 14px;
      color: #71758e;
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}

.partition {
  .owl-prev,
  .owl-next {
    top: 99px;
    position: absolute;
  }

  .owl-prev {
    left: -30px;
  }

  .owl-next {
    right: -30px;
  }

  .owl-nav {
    .owl-prev span {
      width: 58px;
      height: 58px;
      padding-top: 19px;
      border-radius: 100%;
      background-color: #fff;
      display: block;
      position: relative;
      box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.17);
    }

    .owl-next span {
      width: 58px;
      height: 58px;
      padding-top: 19px;
      border-radius: 100%;
      background-color: #fff;
      display: block;
      position: relative;
      box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.17);

      &:before {
        background: url(../images/icon/owl-nav.png);
      }
    }

    .owl-prev span:before {
      background: url(../images/icon/owl-nav.png);
    }

    .owl-next:hover span:before,
    .owl-prev:hover span:before {
      background: url(../images/icon/owl-nav-a.png);
    }

    .owl-next span:before {
      transform: rotate(180deg);
    }

    .owl-prev {
      &:hover span:before,
      span:before {
        width: 100%;
        height: 19px;
        display: block;
        content: ' ';
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .owl-next {
      &:hover span:before,
      span:before {
        width: 100%;
        height: 19px;
        display: block;
        content: ' ';
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}

.owl-dots {
  display: none;
}

.owl-stage {
  display: flex;
  align-items: center;
}

.catalog_vacancy_box {
  margin-top: 50px;

  .h2 {
    padding-left: 10px;
  }

  .btn_lite {
    min-width: 165px;
  }

  @include respond-max('tablet') {
    .btn_lite {
      margin-top: 45px;
    }
  }
}

.cat_vacancy_city_box .h2 {
  padding-left: 10px;
}

.catalog_vacancy_box .item,
.cat_vacancy_city_box .item,
.agency_box .item {
  padding: 10px;
  font-size: 14px;
  color: #3a252a;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    color: $blue;
  }

  .num {
    white-space: nowrap;
  }
}

.catalog_vacancy_box .item:hover,
.cat_vacancy_city_box .item:hover {
  color: $blue;
  background-color: #e2f5ff;
  border-radius: 6px;
}

.catalog_vacancy_box .item .num {
  color: #71758e;
  float: right;
  font-weight: 400;
}

.cat_vacancy_city_box {
  .item {
    .num {
      color: #71758e;
      float: right;
      font-weight: 400;
    }

    &.bold {
      font-weight: 700;
    }
  }

  height: 580px;
  background: #f9fafc;
  border-radius: 6px;
  padding: 27px 35px 0 27px;
}

.vacancyAgency_box {
  margin-top: 50px;
}

.agency_box {
  height: 580px;
  border: 1px solid #dee7ee;
  padding: 27px 35px 0 27px;
  margin-left: 10px;
  border-radius: 6px;

  .item {
    padding-left: 35px;
    display: block;
    position: relative;

    span {
      padding-right: 20px;
      margin-left: -35px;
    }
  }

  .final1,
  .final2,
  .final3 {
    padding-left: 30px;
  }

  .final1:before,
  .final2:before,
  .final3:before {
    width: 30px;
    height: 25px;
    left: -10px;
    content: ' ';
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
  }

  .final1:before {
    background: url('/images/icon/Cup-final 1.svg');
  }

  .final2:before {
    background: url('/images/icon/Cup-final 2.svg');
  }

  .final3 {
    &:before {
      background: url('/images/icon/Cup-final 3.svg');
    }

    margin-bottom: 15px;

    &:after {
      content: ' ';
      height: 1px;
      position: absolute;
      margin-left: -30px;
      margin-top: 52px;
      top: 0;
      left: 30px;
      width: 100%;
      background-color: #e2eaf0;
    }
  }
}

.btn_lite {
  position: absolute;
  bottom: 22px;
  color: $blue;
  border: 1px solid $blue;
}

.footer_box {
  margin-top: 60px;

  .questions {
    height: 70px;
    font-weight: 300;
    font-size: 24px;
    background: #26abe3;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: middle;

    &:hover {
      text-decoration: underline;
    }

    @include respond-max('tablet') {
      height: 75px;
      font-weight: 300;
      font-size: 20px;
    }
  }

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .footer {
    height: 325px;
    padding-top: 45px;
    background: #5d6977;

    .text {
      margin-top: 25px;
      color: #d1dae2;

      ul {
        margin-left: 0;
        padding-left: 0;

        li {
          list-style-type: none;
        }
      }

      a {
        color: #d1dae2;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .logo_lite {
      width: 96px;
      height: 18px;
      padding-bottom: 23px;
      background: url(../images/logo_lite.svg);
      background-repeat: no-repeat;
    }

    .social img {
      margin-top: 25px;
      margin-right: 10px;
    }

    .info {
      display: inline-table;
    }

    .col-4 {
      padding-right: 0;
    }

    .col {
      padding-left: 0;
    }
  }
}

.navbar,
.search_filter.mobile,
ul.mobile {
  display: none;
}

@media (max-width: 1439px) {
  .search_input {
    width: auto;
  }

  .container {
    max-width: 1440px;
  }

  .search_group {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
  }

  .search_input_before {
    width: 64%;
  }

  .search_city .btn-secondary,
  .search_where .btn-secondary {
    margin-top: 0;
  }

  .footer_box .footer {
    font-size: 14px;
  }

  .vacancyAgency_box .btn_lite {
    background-color: #fff;
  }
}

@media (max-width: 1199px) {
  .partition {
    padding: 20px 30px;
  }

  .partition_box .circle {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 990px) {
  .logo {
    margin-left: 0px;
    width: 90px;
    height: 17px;
  }

  .enter {
    margin-right: 35px;
  }

  .search_city .btn-secondary {
    width: 180px;
  }

  .partition_box {
    .circle {
      width: 116px;
      height: 116px;
    }

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: auto;
      min-height: 190px;
    }

    .text {
      padding-top: 15px;
      line-height: 1;

      span {
        position: relative;
      }
    }
  }

  .partition {
    .owl-prev,
    .owl-next {
      top: 80px;
    }
  }

  .navbar {
    display: block;
  }

  .header_box .enter {
    display: none;
  }

  .partition_box {
    margin-top: -191px;
  }

  .footer_box .footer {
    height: auto;
  }

  .col-auto {
    &.mr-auto.mobile {
      width: auto;
      margin: 0 auto;
    }

    &.mobile {
      width: 100%;
    }
  }

  .header_box {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .header_box .point {
    display: none;
  }

  // .search_box .point {
  //   display: block;
  // }
}

@media (max-width: 767px) {
  .search_box {
    height: auto;
    padding: 19px 0 30px;
    margin-bottom: 0px;
    min-height: auto;
  }

  .search_input_before,
  .dropdown,
  .btn_search,
  .search_city .btn-secondary,
  .search_where .btn-secondary {
    width: 100%;
    display: block;
    margin-bottom: 22px;
    border-radius: 6px;
  }

  .search_group {
    height: auto;
    display: block;
    background: none;
  }

  .search_city .btn-secondary,
  .search_where .btn-secondary {
    width: 100%;
  }

  .search_input {
    padding-right: 105px;
    width: 100%;
  }

  .search_input_before {
    position: relative;
  }

  .search_filter {
    display: none;

    &.mobile {
      width: 55px;
      border-radius: 0 6px 6px 0;
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      z-index: 1;
    }
  }

  .close_icon {
    right: 70px;
  }

  .btn_resume,
  .btn_vacancy {
    width: 230px;
    margin: 0 auto;
    display: block;
  }

  .btn_resume {
    margin-bottom: 22px;
  }

  .vacancyAgency_box,
  .catalog_vacancy_box {
    margin-top: 30px;
  }

  .partition_box {
    margin-top: 0;
    overflow: hidden;

    .container {
      padding: 0;
    }

    .partition {
      padding: 30px 0;
    }
  }

  .row.mobile {
    display: block;
  }

  .cat_vacancy_city_box {
    padding: 0;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    height: auto;
  }

  .agency_box {
    padding: 0;
    margin-top: 30px;
    border: none;
    height: auto;
  }

  .btn_lite {
    position: relative;
    margin: 0 auto;
    margin-top: 30px;
  }

  .agency_box {
    .final3:after {
      margin-left: -10%;
      width: 110%;
      left: 0px;
    }

    .d-none {
      display: block !important;
    }
  }

  .info_footer {
    margin-bottom: 25px;
  }

  .footer_box {
    .footer {
      padding-bottom: 30px;

      .info {
        display: block;
      }

      .text {
        margin-top: 15px;
      }
    }

    .info .h3 {
      position: relative;
      line-height: 40px;
      margin: 0;
      cursor: pointer;

      &:before {
        width: 100%;
        content: ' ';
        display: block;
        border-top: 1px solid #818d9b;
      }
    }

    .footer .col {
      padding-left: 15px;
    }
  }

  .col.mobile {
    flex-basis: auto;

    &.d-none {
      display: inline-block;
    }
  }

  .footer_box {
    .info {
      .h3 i {
        width: 12px;
        height: 12px;
        padding-bottom: 23px;
        position: absolute;
        right: 15px;
        top: 11px;
        background: url(../images/icon/toggle.svg) no-repeat center/contain;
        transition: transform ease 0.3s;
      }

      &.active .h3 i {
        top: 6px;
        transform: rotate(-180deg);
      }

      .text {
        display: none;

        ul {
          display: inline-block;
          width: 50%;
        }
      }
    }

    .cityInfo {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .owl-nav {
    display: none;
  }

  .all-cities {
    display: none;
  }
}

@media (max-width: 576px) {
  .search_box {
    background: #009edb;
  }
}

.mob {
  display: none;

  @include respond-max('tablet') {
    display: block;
  }
}
