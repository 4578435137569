/* Media */
// $tablet: 'screen and(max-width: 1199px)'
// $mobile: 'screen and(max-width: 767px)'

/* Fonts */
$roboto: 'Roboto', sans-serif;

/* Colors */
$blue: #089ed9;
$blueMid: #41579e;
$blueLight: #7790e0;
$yellow: #ffd866;
$gray: #999999;
$grayMid: #666666;
$grayDark: #5d6977;
