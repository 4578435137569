html,
body {
  height: 100%;
}

body {
  position: relative;
  font-size: 14px;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  font-family: 'Roboto';
  overflow-x: hidden;
}

*,
:before,
:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  -webkit-locale: auto;
}

svg {
  pointer-events: none;
}

input,
textarea,
button {
  border: none;
  outline: none;
  border-radius: 0;
}

input[type='text'],
input[type='password'],
input[type='tel'],
input[type='email'],
input[type='number'],
textarea,
button {
  -webkit-appearance: none;
}

input::-webkit-credentials-auto-fill-button,
input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

input::-ms-reveal,
input::-ms-clear {
  width: 0;
  height: 0;
}

fieldset {
  border: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: normal;
  color: inherit;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

li {
  list-style: none;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}
