/**
 * Variables
 */

@import 'settings/colors';
@import 'settings/breakpoints';

/**
  * Functions, mixins
  */

@import 'tools/functions';
@import 'tools/mixins';

/**
 * Vendor
 */

@import 'vendor/import';
@import 'vendor/bootstrap';
@import 'vendor/owl.carousel';

/**
 * Basic rules
 */

@import 'base/base';
@import 'base/fonts';
@import 'base/vars';

/**
 * Components
 */

@import 'components/header';
@import 'components/home';
@import 'components/footer';
